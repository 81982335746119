<template>
    <div>
        <div class="r-option-place r-center-flex r-isWrap">
            <InputCustom
                :rightIcon="true"
                :withError="false"
                v-model="request.search" 
                @keyup="setTime()" 
                @keydown="clearTime()" 
                @keydown.tab="setTime()" 
                @keyup.tab="clearTime()" 
                @keypress.enter="getData()" 
                placeholder="Search . . ."
                size='md'
                >
                <template #rightIcon>
                    <div class="y-inputIcon r-center-flex">
                        <img :src="require('@/assets/icons/others/search.svg')" alt="search">
                    </div>
                </template>
            </InputCustom>
        </div>

        <TableCustom 
            :columns="columns" 
            :request="request" 
            :fetchData="fetchData"
            :data="data"
            @setSortBy="setSortBy"
            @setShow="setShow" 
            @getData="getData"
            @mouseleave="popup = null"
            @togglePopup="togglePopup"
        >
            <template #index="props">
                {{curNumber(props.index)}}
            </template>

            <template #created_at="props">
                {{convertDateTime(props.row.created_at)}}
            </template>

            <template #approved_by="props">
                {{props.row.approved_by}}
            </template>

            <template #updated_by="props">
                {{props.row.updated_by}}
            </template>

            <template #username="props">
                {{props.row.username}}
            </template>

            <template #rekening="props">
                {{props.row.name}} - {{props.row.nama_rek}} - {{props.row.no_rek}}
            </template>

            <template #email="props">
                {{props.row.email}}
            </template>
            
            <template #no_ponsel="props">
                {{props.row.no_ponsel}}
            </template>
            
            <template #saldo="props">
                {{props.row.saldo}}
            </template>
            
            <template #ip_address="props">
                {{props.row.ip_address != null ? props.row.ip_address : '-'}}
            </template>
            
            <template #last_login="props">
                {{props.row.last_login != null ? props.row.last_login : '-'}}
            </template>

            <template #action="props">
                <div class="r-menu">
                    <img class="r-is-clickable" :src="require('@/assets/icons/others/more.svg')" @click.prevent="popup = curNumber(props.index)">
                    <div class="r-menu-items" v-if="popup == curNumber(props.index)" @mouseleave="popup = -1">
                        <div class="r-menu-item y-black-text r-center-flex" @click.prevent="openEditModal(props.row)">Edit</div>
                        <div class="r-menu-item y-black-text r-center-flex" @click.prevent="goToDownlinePelanggan(props.row)">Downline</div>
                        <div class="r-menu-item y-black-text r-center-flex" @click.prevent="openDepositModal(props.row)">Deposit</div>
                        <div class="r-menu-item y-black-text r-center-flex" @click.prevent="openWithdrawModal(props.row)">Withdraw</div>
                        <div class="r-menu-item y-black-text r-center-flex" @click.prevent="openEditModal(props.row)">Rekap</div>
                        <div class="r-menu-item y-black-text r-center-flex" @click.prevent="openEditModal(props.row)">Notifikasi</div>
                        <div class="r-menu-item y-black-text r-center-flex" @click.prevent="openBonusModal(props.row)">Bonus</div>
                        <div class="r-menu-item y-black-text r-center-flex" @click.prevent="CalculateSaldoPlayer(props.row)">Calculate</div>
                    </div>
                </div>
            </template>
        </TableCustom>

        <DepositModal 
            v-if="modal == 'deposit'" 
            :inputType="inputType"
            @resetData="resetData"
            @getData="getData"
        ></DepositModal>

        <WithdrawModal
            v-if="modal == 'withdraw'" 
            :inputType="inputType"
            @resetData="resetData"
            @getData="getData"
        >
        </WithdrawModal>

        <BonusModal
            v-if="modal == 'bonus'" 
            @resetData="resetData"
            @getData="getData"
        >
        </BonusModal>

        <UpdateModal
            v-if="modal == 'Simpan'" 
            :inputType="inputType"
            @resetData="resetData"
            @getData="getData"
        >
        </UpdateModal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import common from '@/mixins/function/common'
import tableMixins from '@/mixins/tableMixins'
import UpdateModal from '@/components/Pelanggan/UpdateModal'
import WithdrawModal from '@/components/Transaction/WithdrawModal'
import DepositModal from '@/components/Transaction/DepositModal'
import BonusModal from '@/components/Transaction/BonusModal'
export default {
    mixins: [
        common, 
        tableMixins
    ],
    components: {
        WithdrawModal,
        UpdateModal,
        DepositModal,
        BonusModal
    },
    mounted() {
        this.initData();
    },
    computed: {
        ...mapGetters ({
            data: 'player/getData',
            request: 'player/getRequest',
            item: 'player/getItem',
            listRekening: 'rekening/getRekenings'
        }),

    },
    data: () => ({
        columns: [
            {
                label: 'No',
                isSortable: false,
                fieldName: 'index',
                class: 'r-table-firstColumn'
            },
            {
                label: 'Tanggal Daftar',
                isSortable: true,
                fieldName: 'created_at',
                class: ''
            },
            {
                label: 'Approved By',
                isSortable: true,
                fieldName: 'approved_by',
                class: ''
            },
            {
                label: 'Updated By',
                isSortable: true,
                fieldName: 'updated_by',
                class: ''
            },
            {
                label: 'Username',
                isSortable: true,
                fieldName: 'username',
                class: ''
            },
            {
                label: 'Rekening',
                isSortable: false,
                fieldName: 'rekening',
                class: ''
            },
            {
                label: 'Email',
                isSortable: false,
                fieldName: 'email',
                class: ''
            },
            {
                label: 'No Ponsel',
                isSortable: false,
                fieldName: 'no_ponsel',
                class: ''
            },
            {
                label: 'Saldo',
                isSortable: true,
                fieldName: 'saldo',
                class: ''
            },
            {
                label: 'IP Address',
                isSortable: false,
                fieldName: 'ip_address',
                class: ''
            },
            {
                label: 'Last Login',
                isSortable: true,
                fieldName: 'last_login',
                class: ''
            },
            {
                label: 'Action',
                isSortable: false,
                fieldName: 'action',
                class: 'r-table-actionColumn'
            }
        ],
        modal: null,
        inputType: 'Tambah',
        statusLoading: null,
        status: 'Online',
    }),
    methods: {
        ...mapActions ({
            resetItem: 'player/resetItem',
            resetRequest: 'player/resetRequest',
            setItem: 'player/setItem',
            setItemTransaction: 'transaction/setItem',
            getPlayer: 'player/fetchData',
            getAllBank: 'bank/getAllBank',
            getAllRekening: 'rekening/getAllRekening',
            calculatePlayer: 'player/calculatePlayer',
        }),
        setData(item) {
            item.name = item.player
            this.setItem(item)
            this.openModal('Ubah')
        },
        openDepositModal(item) {
            var temp = this.listRekening.filter(function (rekening) {
                if (rekening.id_bank == item.id_bank) {
                    return rekening.id
                }
            })
            item.id_player = item.id
            item.id_to_rek = temp[0].id
            item.jenis_transaksi = 'Deposit'
            this.setItemTransaction(item)
            this.inputType = 'Deposit'
            this.modal = 'deposit'
        },
        openEditModal(item) {
            this.setItem(item)
            this.inputType = 'Simpan'
            this.modal = 'Simpan'
        },
        openWithdrawModal(item) {
            item.id_player = item.id
            item.jenis_transaksi = 'Withdraw'
            this.setItemTransaction(item)
            this.inputType = 'Withdraw'
            this.modal = 'withdraw'
        },
        openBonusModal(item) {
            item.id_player = item.id
            this.setItemTransaction(item)
            this.modal = 'bonus'
        },
        resetData() {
            this.modal = null
            this.statusLoading = null
            this.resetItem()
            this.resetRequest()
        },
        async getData(type = 0) {
            this.fetchData = true
            if (type === 0) {
                this.request.page = 1
            } else {
                this.request.page += type
            }
            await this.getPlayer()
            this.fetchData = false
            const response = this.getResponse('player')
            if (response.status !==1) {
                this.showSnackbar ({
                    type: 'error',
                    text: response.msg
                })
            }
        },
        async initData() {
            const breadcrumb = [{
                name: 'Data Player',
                link: {name: null},
                current: true
            }]
            this.setActiveTab('Pelanggan')
            this.setBreadCrumb(breadcrumb)
            await this.getData()
            await this.getAllBank()
            await this.getAllRekening()
        },
        async CalculateSaldoPlayer(item) {
            this.loading = true
            await this.calculatePlayer(item)
            this.loading = false
            const response = this.getResponse('player')
            this.showSnackbar({
                type: response.status == 1 ? 'success' : 'error',
                text: response.msg
            })

            if (response.status === 1) {
                this.resetData()
                await this.getData()
            }
        },
        goToDownlinePelanggan(item) {
            this.$router.push({name: 'ViewDownlinePelanggan', params: {id: item.id}})
        },
    }
}
</script>