<template>
    <Modal label="Deposit">
        <InputCustom
            v-model="item.username"
            :error="error.username"
            :errorMsg="errorMsg.username"
            @onFocus="resetError('username')"
            placeholder="Username"
            label="Username"
            readonly
        >
        
        </InputCustom>

        <div class="y-inputField">
            <div class="y-labelText">
                Dari Rekening
            </div>
            <div class="y-inputPlace y-hasIcon-right">
                <div class="y-select-hasIcon" style="width: 100%">
                    <select class="y-select" disabled>
                        <option class="y-option">
                            {{item.bank_name}} - {{item.no_rek}} - {{item.nama_rek}}
                        </option>
                    </select>
                </div>
            </div>
            <div
                class="y-errorText y-red-text"
                :style="error.id_to_rek ? 'opacity: 1 !important;': ''"
            >
                {{errorMsg.id_to_rek}}
            </div>
        </div>

        <div class="y-inputField">
            <div class="y-labelText">
                Ke Rekening
            </div>
            <div class="y-inputPlace y-hasIcon-right">
                <div class="y-select-hasIcon" style="width: 100%">
                    <select class="y-select" v-model="item.id_to_rek">
                        <option class="y-option"
                            v-for="(l,i) in listRekening" 
                            :key="`list-data-${i}`"
                            :value="l.id"
                            :selected="l.id_bank == item.id_bank"
                        >
                            {{l.bank}} - {{l.no_rek}} - {{l.rekening}}
                        </option>
                    </select>
                    <div class="y-select-icon">
                        <img :src="require('@/assets/icons/others/arrowDown.svg')" alt="">
                    </div>
                </div>
            </div>
            <div
                class="y-errorText y-red-text"
                :style="error.id_to_rek ? 'opacity: 1 !important;': ''"
            >
                {{errorMsg.id_to_rek}}
            </div>
        </div>
        
        <InputCustom
            v-model="item.jumlah" 
            :error="error.jumlah"
            :errorMsg="errorMsg.jumlah"
            @onFocus="resetError('jumlah')"
            placeholder="Jumlah Deposit"
            label="Jumlah Deposit"
        >
        </InputCustom>

        <TextareaCustom
            v-model="item.keterangan" 
            :error="error.keterangan"
            :errorMsg="errorMsg.keterangan"
            @onFocus="resetError('keterangan')"
            placeholder="Keterangan"
            label="Keterangan"
        >
        </TextareaCustom>
        
        <template #actionButton>
            <ButtonCustom
                :disabled="loading"
                type="secondary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="resetData()"
            >
                Batal
            </ButtonCustom>
            
            <ButtonCustom
                :disabled="loading"
                type="primary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="sendData()"
            >
                Deposit
            </ButtonCustom>
        </template>
    </Modal>
</template>
<script>
import common from '@/mixins/function/common'
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/Helper/Modal'
import validation from '@/mixins/function/validation'
import TextareaCustom from '@/components/Helper/Textarea'
export default {
    mixins: [
        common,
        validation
    ],
    components: {
        Modal,
        TextareaCustom
    },
    mounted() {
        this.loadBanks = true
    },
    props: {
        inputType: {
            require: true,
        }
    },
    computed: {
        ...mapGetters({
            item: 'transaction/getItem',
            listRekening: 'rekening/getRekenings'
        })
    },
    data: () => ({
        loadBanks: false,
        error: {
            id_to_rek: null,
            jumlah: null,
        },
        errorMsg: {
            id_to_rek: 'Tidak boleh kosong',
            jumlah: 'Tidak boleh kosong',
        },
        loading: false,
    }),
    methods: {
        ...mapActions({
            store: 'transaction/store',
        }),
        resetData() {
            this.$emit('resetData')
        },
        validation() {
            var error = 0
            var field = ['jumlah', 'id_to_rek']
            for (var i = 0; i < field.length; i++){
                if(this.item[field[i]] === null || this.item[field[i]]  === ''){
                    this.error[field[i]] = true
                    error += 1;
                }else{
                    this.error[field[i]] = false
                }
            }
            if(this.item.jumlah !== null && this.item.jumlah !== ""){
                if(!(/^\d+$/.test(this.item.jumlah))){
                    this.error.jumlah = true
                    this.errorMsg.jumlah = "Hanya boleh angka"
                    error += 1;
                } else{
                    this.error.jumlah = false
                }
            }
            return error
        },
        async sendData() {
            if (this.validation() === 0) {
                this.loading = true
                await this.store(this.item)
                this.loading = false
                const response = this.getResponse('transaction')
                this.showSnackbar({
                    type: response.status == 1 ? 'success' : 'error',
                    text: response.msg
                })

                if (response.status === 1) {
                    this.resetData()
                    this.$emit('getData')
                }
            } else {
                this.showSnackbar ({
                    type: 'error',
                    text: 'Mohon periksa kembali'
                })
            }
        },
    }
}
</script>